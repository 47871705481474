import { useStaticQuery, graphql } from "gatsby"

export const useStartPage = () => {
  const { sanityStartpage } = useStaticQuery(graphql`
    query {
      sanityStartpage(sanityParent: { _id: { eq: "48de759c-c605-4832-b2ee-4a9285da510e" } }) {
        hero {
          contentPosition
          kontaktToPopup
          herotype
          heading
          bgimage {
            asset {
              gatsbyImageData(width: 1000)
            }
          }
          subheading {
            children {
              text
              _type
              _key
              marks
            }
            _rawChildren
          }
        }
        cta {
          primaryButton {
            link
            text
          }
          secondaryButton {
            link
            text
          }
        }
        seo {
          title
          lang
          description
          author
        }
        content {
          ... on SanityImageGrid {
            _key
            _type
            bgColor
            heading
            gridImages {
              _key
              _type
              heading
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
          ... on SanityTextImage {
            _key
            _type
            heading
            layout
            image {
              asset {
                gatsbyImageData
              }
            }
            _rawContent
          }
        }
      }
    }
  `)

  return sanityStartpage || {}
}
