/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSettings } from "../hooks/useSettings"

function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
  }
}

function fontUrl(string) {
  return string?.split(" ").join("+")
}

function Seo({ description, title, children, seo }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const { primaryColor, secondaryColor, font } = useSettings()
  const metaDescription = description || site.siteMetadata.description
  return (
    <>
      {<title>{seo?.title}</title> || <title>Title</title>}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href={`https://fonts.googleapis.com/css2?family=${
          font ? fontUrl(font) : "Lora"
        }:ital,wght@0,400;0,600;1,400;1,600&display=block`}
        rel="stylesheet"
      />
      <style>
        {`
        :root {
          --primary: ${hexToRgbA(primaryColor)};
          --secondary: ${hexToRgbA(secondaryColor)}
        }
          body {
            font-family: ${font || "Lora"};
          }`}
      </style>
      <meta name="description" content={seo?.description} />
      <meta name="author" content={seo?.author} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      {children}
    </>
  )
}

export default Seo
