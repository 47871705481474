import * as React from "react"

// import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStartPage } from "../hooks/useStartPage"

const NotFoundPage = () => (
  // <Layout>
  <h1>404: Not Found</h1>
  // <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  // </Layout>
)

export const Head = () => {
  const { seo } = useStartPage()
  return <Seo title="404: Not Found" seo={seo} />
}

export default NotFoundPage
