import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () => {
  const { sanitySettings } = useStaticQuery(graphql`
    query {
      sanitySettings(sanityParent: { _id: { eq: "48de759c-c605-4832-b2ee-4a9285da510e" } }) {
        navigation {
          title
          slug {
            current
          }
          pageType
        }
        logo {
          asset {
            gatsbyImageData(height: 40)
          }
        }
        callInHeader
        gaid
        primaryColor
        secondaryColor
        font
        favicon {
          asset {
            gatsbyImageData
          }
        }
        nimbata
      }
    }
  `)

  return sanitySettings || {}
}
